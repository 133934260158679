.header {
    /* padding: 0; */
    color: white !important;
    background-color: #4350af !important;
}

.nav-menu-btn,
.logout-btn {
    width: 64px;
    height: 64px;
}

.left-menu {
    font-size: 18px;
    margin-top: 15px;
}

.left-mobile-menu {
    font-size: 16px;
}

.ant-drawer-content-wrapper {
    width: 100% !important;
}

.resp-drawer .ant-drawer-body {
    padding: 0 !important;
}

.resp-drawer {
    width: 42% !important;
}

.collapsed-menu {
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
}