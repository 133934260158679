@import url('https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');
:root {
    --iblue: rgb(22 119 255);
    --white: #ffffff;
    --black: #000000;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-family: 'Teachers', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

body {
    margin: 0;
    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

html::-webkit-scrollbar {
    display: none;
}
/* styles which have in many files */
.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
    color: var(--white);
    background-color: var(--iblue);
}

.ant-table-expanded-row table .ant-table-cell {
    color: var(--black) !important;
    background-color: #fafafa !important;
}

.action-btn {
    width: 110px;
}
.menu-icon {
    color: white;
    font-size: 24px !important;
}
.dyn-submit-btn {
    width: 100%;
    background-color: #2e7d3a;
}
.add-section-btn {
    width: 100%;
    font-size: 16px;
    margin-top: 24px;
    padding: 0 10px;
}
label {
    font-size: 16px !important;
}
.dubl-img {
    width: 15px !important;
    height: 15px !important;
}
.dubl-btn {
    margin-right: 15px;
    background-color: #6c757d;
    color: #fff;
}
.add-btn {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.ant-collapse-expand-icon {
    margin-top: 4px;
}
.folder-icon {
    color: black;
    margin-right: 8px;
}
.ant-tabs-nav-more {
    display: none !important;
}
.upload-question .ant-upload {
    width: 100%;
}
.hidden-field {
    visibility: hidden;
}
.title-folder-icon {
    margin-right: 10px;
    color: #f3d883 !important;
}
/* Common reuseable styles */
.d-none {
    display: none !important;
}
.d-i-block {
    display: inline-block !important;
}
.text-align-right {
    text-align: right !important;
}
.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-flex-even {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.d-flex-align {
    display: flex;
    align-items: center;
}
.d-flex-btw {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.d-flex-end {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
}
.success-btn {
    background-color: green;
    color: white;
}
.ant-spin-dot {
    top: 45vh !important;
}
/* commonly used styles */
.full-width {
    width: 100% !important;
}
.half-width {
    width: 50% !important;
}
.auto-width {
    width: auto !important;
}
.width-auto {
    width: auto !important;
}
.float-right {
    float: right !important;
}
.float-left {
    float: left !important;
}
.mt-10p {
    margin-top: 10px;
}
.m-15p {
    margin-top: 15px;
}
.p-15p {
    padding: 15px;
}
.p-24p {
    padding: 24px !important;
}
.mt-15p {
    margin-top: 15px;
}
.mt-20p {
    margin-top: 20px;
}
.mt-25p {
    margin-top: 20px;
}
.mr-5p {
    margin-right: 10px;
}
.mr-10p {
    margin-right: 10px;
}
.mr-15p {
    margin-right: 15px;
}
.mr-20p {
    margin-right: 20px;
}
.ml-10p {
    margin-left: 10px;
}
.ml-15p {
    margin-left: 15px;
}
.ml-20p {
    margin-left: 20px;
}
.mt-5v {
    margin-top: 5vh !important;
}
.mb-10p {
    margin-bottom: 10px;
}
.mb-15p {
    margin-bottom: 15px;
}
.mb-20p {
    margin-bottom: 20px;
}

@media only screen and (max-width: 1500px) {
    .filter-box {
        grid-template-columns: repeat(3, 33%);
        gap: 32px 32px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (max-width: 992px) {} */

/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 768px) {
    /* sign-in */
    .login-form {
        width: 100% !important;
        padding: 0 20px;
    }
    .login-container {
        width: 100%;
    }
    /* practice */
    .practice-container {
        padding: 10px;
    }

    .w-100per {
        width: 100% !important;
    }
    .transfer-arrow-btns .arrow-left {
        margin-top: 0 !important;
    }
    .transfer-arrow-btns {
        min-height: 100px;
        flex-direction: row;
        align-items: center;
        margin-top: 0px !important;
    }
    /* main layout */
    .main-layout-content {
        margin: 0 !important;
        padding: 24px 15px;
    }

    /* AddQuestion.tsx */
    .question-collapse .ant-collapse-content-box {
        padding: 6px !important;
    }
    .practice-question-card .ant-card-body {
        padding: 10px;
    }

    .practice-card .ant-card-body {
        padding: 0 !important;
    }
    /* ck-editor edit box */
    .ck-editor__editable {
        height: 100px !important;
    }

    /* common usable styles */
    .mobile-full-width {
        width: 100% !important;
    }
    .mobile-mt-0 {
        margin-top: 0 !important;
    }
    .mobile-d-flex-btw {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
    .mobile-p-0 {
        padding: 0 !important;
    }
    .mobile-p-5p {
        padding: 5px !important;
    }
    .mobile-p-15p {
        padding: 15px !important;
    }
    .mobile-fs-22 {
        font-size: 22px !important;
    }
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {} */

.ck-content strong {
    font-weight: bold;
}

.ck-content i {
    font-style: italic;
}

.ck-content i strong {
    font-weight: bold;
    font-style: italic;
}
