.table-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.search-input {
    width: 300px;
}

@media only screen and (max-width: 768px) {
    .table-wrapper {
        flex-direction: column;
    }
    .search-input {
        margin-top: 10px;
        width: 100%;
    }
}
