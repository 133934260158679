.transfer-list-box {
    min-height: 250px;
    border-radius: 10px 10px 0 0;
}
.transfered-section {
    color: green !important;
    /* background-color: #2e7d3a !important; */
}
.transfer-arrow-btns {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
}
.transfer-save-btn {
    border-radius: 0 0 10px 10px;
}
.dyn-submit-btn {
    width: 100%;
    background-color: #2e7d3a;
}
